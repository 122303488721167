import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";

type AuthNavItemProps = {
  id: string;
  link: string;
  text: string;
};

export const AuthNavItem = ({ id, link, text }: AuthNavItemProps) => {
  const router = useRouter();

  return (
    <Link
      id={id}
      href={link}
      className={clsx(
        "rounded-sm px-3 py-2 text-sm font-medium text-gray-900 md:text-gray-300 md:hover:bg-gray-600 md:hover:text-white",
        router.asPath === link && "underline"
      )}
    >
      {text}
    </Link>
  );
};
