import { useAuthModal } from "features/auth/context/AuthModalContext";
import { EAuthFormType } from "features/auth/types/auth.types";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { MouseEvent } from "react";
import { triggerAnalyticsEvent } from "utils/analytics";

type LoginButtonProps = {
  id: string;
};

export const LoginButton = ({ id }: LoginButtonProps) => {
  const router = useRouter();
  const { setAuthModalSettings } = useAuthModal();
  const { t } = useTranslation();

  const onClose = () => {
    triggerAnalyticsEvent("login_button_cancel", {
      url: router.asPath,
    });
  };

  const onSuccess = () => {
    triggerAnalyticsEvent("login_button_success", {
      url: router.asPath,
    });
  };

  const handleOpen = (e: MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    triggerAnalyticsEvent("login_button_click", {
      url: router.asPath,
    });
    setAuthModalSettings({
      show: true,
      action: EAuthFormType.LOGIN,
      onClose,
      onSuccess,
    });
  };

  return (
    <button
      id={id}
      className="rounded-sm px-3 py-2 text-sm font-medium text-black md:text-white md:hover:bg-gray-600"
      onClick={handleOpen}
    >
      {t("navigation-login")}
    </button>
  );
};
