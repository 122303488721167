import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import { IconType } from "react-icons";

type BottomNavItemProps = {
  link: string;
  text: string;
  icon: IconType;
};

export const BottomNavItem = ({
  link,
  text,
  icon: Icon,
}: BottomNavItemProps) => {
  const router = useRouter();

  return (
    <Link
      href={link}
      type="button"
      className={clsx(
        "group inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50",
        router.asPath === link && "text-primary-500"
      )}
    >
      <Icon size={28} />
      <span className="text-sm font-normal">{text}</span>
    </Link>
  );
};
