import { isProd } from "constants/env";
import { TranslatedSlugDef } from "features/translations/types/translation.types";
import NextHead from "next/head";
import { clearUpMetaText } from "utils/helpers";
import { transformImage } from "utils/image.helper";

export type HeadProps = {
  meta?: {
    title?: string;
    og_title?: string;
    description?: string;
    og_description?: string;
    og_image?: { filename: string } | string;
    twitter_image?: { filename: string } | string;
    twitter_title?: string;
    twitter_description?: string;
  };
  locale: string;
  translatedSlugs: TranslatedSlugDef[];
  hideFromRobots?: boolean;
};

const Head = ({ meta, locale, translatedSlugs, hideFromRobots }: HeadProps) => {
  const metaTitle = meta?.og_title || meta?.title;
  const title =
    !!metaTitle && metaTitle !== "Crafthunt"
      ? `${clearUpMetaText(metaTitle, 80)} | Crafthunt`
      : "Crafthunt";
  const twitter_title =
    meta?.twitter_title && clearUpMetaText(meta.twitter_title, 80);
  const metaDescription = meta?.og_description || meta?.description;
  const description = metaDescription && clearUpMetaText(metaDescription, 155);
  const twitter_description =
    meta?.twitter_description && clearUpMetaText(meta.twitter_description, 155);

  const currentPath = translatedSlugs?.[0]?.path || "";
  const currentFullPath = `https://crafthunt.app${
    currentPath === "/" ? "" : currentPath
  }`;

  const alternateLinks: { lang: string; link: string }[] = [
    {
      lang: "x-default",
      link: currentFullPath,
    },
    ...(translatedSlugs || []).map((item) => ({
      lang: item.lang,
      link: `https://crafthunt.app${item.lang !== "de" ? "/" + item.lang : ""}${
        item.path === "/" ? "" : item.path
      }`,
    })),
  ];

  return (
    <NextHead>
      {isProd &&
        (hideFromRobots ? (
          <meta name="robots" content="none" />
        ) : (
          <meta
            name="robots"
            // Tell search engine to index, follow links, show full text snippets, large image and video previews
            content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          />
        ))}

      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="og:type" content="website" />
      <meta name="og:site_name" content="Crafthunt" />
      <meta name="og:url" content="https://crafthunt.app" />
      <meta name="twitter:creator" content="@crafthunt" />
      <meta name="twitter:site" content="@crafthunt" />
      <meta name="twitter:card" content="summary" />

      <title>{title}</title>
      <meta property="og:title" content={title} />

      {!!description && (
        <>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
        </>
      )}
      {!!meta?.og_image && (
        <>
          {/* Facebook */}
          <meta
            property="og:image"
            content={transformImage(
              typeof meta.og_image === "string"
                ? meta.og_image
                : meta.og_image.filename,
              "1200x630/smart/filters:quality(80)"
            )}
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          {/* LinkedIn */}
          <meta
            property="og:image"
            content={transformImage(
              typeof meta.og_image === "string"
                ? meta.og_image
                : meta.og_image.filename,
              "1200x627/smart/filters:quality(80)"
            )}
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="627" />
        </>
      )}
      {!!meta?.twitter_image && (
        <meta
          property="twitter:image"
          content={transformImage(
            typeof meta.twitter_image === "string"
              ? meta.twitter_image
              : meta.twitter_image.filename,
            "1200x600/filters:quality(80)"
          )}
        />
      )}
      {!!twitter_title && (
        <meta property="twitter:title" content={twitter_title} />
      )}
      {!!twitter_description && (
        <meta property="twitter:description" content={twitter_description} />
      )}

      {alternateLinks?.map((item) => (
        <link
          key={item.lang}
          rel="alternate"
          hrefLang={item.lang}
          href={item.link}
        />
      ))}
      {/* Add canonical link when visiting the german site to clearly indicate which one is the preferred one */}
      {locale === "de" && <link rel="canonical" href={currentFullPath} />}
    </NextHead>
  );
};

export default Head;
