import { CustomButton } from "@crafthunt-ui/CustomButton/CustomButton";
import { useAuthModal } from "features/auth/context/AuthModalContext";
import { EAuthFormType } from "features/auth/types/auth.types";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { MouseEvent } from "react";
import { triggerAnalyticsEvent } from "utils/analytics";

type SignUpButtonProps = {
  id: string;
};

export const SignUpButton = ({ id }: SignUpButtonProps) => {
  const router = useRouter();
  const { setAuthModalSettings } = useAuthModal();
  const { t } = useTranslation();

  const onClose = () => {
    triggerAnalyticsEvent("sign_up_button_cancel", {
      url: router.asPath,
    });
  };

  const onSuccess = () => {
    triggerAnalyticsEvent("sign_up_button_success", {
      url: router.asPath,
    });
  };

  const handleOpen = (e: MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    triggerAnalyticsEvent("sign_up_button_click", {
      url: router.asPath,
    });
    setAuthModalSettings({
      show: true,
      action: EAuthFormType.SIGN_UP,
      onClose,
      onSuccess,
    });
  };

  return (
    <CustomButton id={id} onClick={handleOpen}>
      {t("navigation-sign-up")}
    </CustomButton>
  );
};
